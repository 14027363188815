import React, {Component} from 'react';
import Banner from './Banner';
import Card from './Card';
import './content.css';

import * as content from './new_content' 

class Portfolio extends Component {

	
	constructor(props){
		super(props);
		this.state = {appState: 'HOME'};
	}
	select = (key) => {
		this.setState({appState:key});
	}
	atHome = () => this.state.appState === 'HOME';
	
	render(){
		return (
			<div className="App" onScroll={()=>this.select()} onClick={()=>this.select()}>
				<Banner className="banner" ref={this.appState} home={this.atHome()} onClick={()=>this.select("HOME")}>
				</Banner>

				<div className={`section-container ${this.atHome() && 'hidden'}`}>
					<div className={"card-list active"}>
						<ul>
								{content.cards.map(c=>{
									return <li key={c.title}><Card content={c} ></Card></li>
								})}
						</ul>
					</div>
				</div>
				<div className="footer"></div>
			</div>
		)
	}
}

export default Portfolio;