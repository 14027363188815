import React, {Component} from 'react';
import './Banner.css';
import './Menu.css';
import {FaCircleChevronDown, FaEnvelope, FaLinkedin, FaGithub, FaUpwork, FaSoundcloud} from 'react-icons/fa6';

class Banner extends Component {
	render(){
		return (
			<div className={'banner ' + (this.props.home ? 'home':'away')} onClick={this.props.onClick}>
				<h1>
				<span className={"bannerspan " + (this.props.home ? 'home':'away')}>
				Shaagnik Mukherji
				<ul className={'buttons ' + (this.props.home ? 'home':'away')}>
					<li><a href="mailto:shaagnik.m@gmail.com" target="_blank"><FaEnvelope /></a></li>
					<li><a href="https://www.linkedin.com/in/shaagnik-mukherji-8082b6167" target="_blank"><FaLinkedin /></a></li>
					<li><a href="https://www.github.com/shaagnik" target="_blank"><FaGithub /></a></li>
					<li><a href="https://www.soundcloud.com/shaagnik" target="_blank"><FaSoundcloud /></a></li>
				</ul>
				</span>
				</h1>
				<ul className={'links ' + (this.props.home ? 'home':'away')}>
					<li><a href="mailto:shaagnik.m@gmail.com" target="_blank">email</a></li>
					<li><a href="https://www.linkedin.com/in/shaagnik-mukherji-8082b6167" target="_blank">linkedin</a></li>
					<li><a href="https://www.github.com/shaagnik" target="_blank">github</a></li>
					<li><a href="https://www.soundcloud.com/shaagnik" target="_blank">soundcloud</a></li>
				</ul>
				{this.props.home && <div className="down">
					<FaCircleChevronDown />
				</div>}
			</div>

		)
	}
}

export default Banner;