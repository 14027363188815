import React, {Component} from 'react';

class Card extends Component {
	constructor(props) {
		super(props);
		this.content = props.content;
	}
	render(){
		return <div className="card">

			<div className="text">
				<h3>{this.content.title}</h3>
				<div dangerouslySetInnerHTML={this.content.content}></div>
			</div>
			<div className="card-img">
			{!!this.content.image &&
				<img src={this.content.image} alt=""></img>
			}
			{!!this.content.embed &&
				<iframe width="100%" height="300" src={"https://www.youtube.com/embed/" + this.content.embed} frameBorder="0" allow="encrypted-media; picture-in-picture;" allowFullScreen></iframe>
			}

			{!!this.content.sc &&
				<iframe width="100%" height="300" frameBorder='no' src={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/" + this.content.sc + "&color=%23741484&auto_play=false&visual=true"}></iframe>
			}
			</div>
		</div>
	}
}

export default Card;