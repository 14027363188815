import React from 'react';
import Portfolio from './Portfolio';
import './App.css';

function App() {

  return (
    <Portfolio />
  );
}

export default App;
