var cards = [
        {
            title:'Cinematic Sound Design, Music, Foley - clip from CABIN IN THE WOODS',
            content: {__html:"<p>sound design, editing, music, foley</p>"},
            embed: 'x9a6gqI-GbQ'
        },        
        {
            title:'Game Sound Design - 3D Adventure Game',
            content: {__html:"<p>sound design/music for Unity tutorial game</p>"},
            embed: 'JRSe4GmOdbs'
        },
        {
            title:'Game Sound Design - Stealth Game',
            content: {__html:"<p>sound design/music for Unity tutorial game</p>"},
            embed: 'F7Qy66w0oOI'
        },

        {
            title:'Sound and Visual Design - The Blob Sea',
            content: {__html:"<p>Touch Designer installation - used 8 projectors to create an immersive sound environment. The 'steering wheel' changes the angle as the room 'sails' through a sea of abstract sound sources.</p>"},
            embed: 's-ZE_vEEG4U'
        },  {
            title:'Sound Design - Big Momma',
            content: {__html:"<p>sound design, editing</p>"},
            embed: 'SsN0PqcPYtI'
        },
        {
            title:'Cinematic Sound Design - clip from JAWS',
            content: {__html:"<p>sound design, editing, foley</p>"},
            embed: 'gbggS-tNqMQ'
        },
        {
            title:'Procedural Audio Generation - clip from ALIEN',
            content: {__html:"<p>procedural techniques built in Max to create sounds for the power loader's movement and steps.</p>"},
            embed: 'sa2c5_LIzY8'
        },
{
			title: 'Fred',
			content: {__html:"<p>personal experimental project - a genetic algorithm that slowly generates musical compositions</p>"+
			"<p><a href='https://github.com/shaagnik/fred'>github repository</a></p>"},
			sc:'1355849329'
},
{
			title: 'Personal Recordings',
			content: {__html:"<p>all instruments, recording and production - covers and originals</p>"},
			sc:"1355921725"
},{
			title: 'Castles',
			content: {__html:"<p>bass</p>"},
			sc:"1355857513"
		
		}, {
			title: 'Zeitghost',
			content: {__html:"<p>bass, vocals, guitar</p>"},
			sc:"1355921905"
		
		}

]
export {cards}
